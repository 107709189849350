import Vue from 'vue'
import Axios from 'axios'
import AxiosFinally from 'promise.prototype.finally'
import AxiosRetry from 'axios-retry'
import lodash from 'lodash'
import store from '@/stores'

const lnInterceptor = function(req) {
  if (store.getters['lang/current']) {
    req.baseURL = req.baseURL.replace('/fr/', `/${store.getters['lang/current']}/`)
  }
  return req
}

const errorInterceptor = function(err) {
  if (!err.response) {
    return Promise.reject(err)
  }
  return Promise.reject(err)
}
// pour gérer le .finally
AxiosFinally.shim()

Vue.prototype.$http = Axios.create({})
Vue.prototype.$http.interceptors.response.use(res => {
  return res
})

Vue.prototype.$api = Axios.create({
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  baseURL: '' // sera configuré lors du boot
})
Vue.prototype.$api.interceptors.request.use(lnInterceptor)
Vue.prototype.$api.interceptors.response.use(
  res => {
    return res
  },
  errorInterceptor
)

AxiosRetry(Vue.prototype.$api, {
  retryCondition: err => AxiosRetry.isNetworkOrIdempotentRequestError(err) || lodash.get(err, 'response.data.retryable'),
  retryDelay: retryCount => {
    return AxiosRetry.exponentialDelay(retryCount) + (2 * 1000)
  },
  retries: 5
})

export default Axios
